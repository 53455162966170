import React from "react"
import SEO from "../components/seo"
import "../bootstrap.min.css"

import { H1, H2, H3, P, PSmall } from "../components/text"
import Section from "../components/section"
import Footer from "../components/footer"
import Button from "../components/button"

import Header from "../components/header"
import Hero from "../components/hero"

// Images
import imageCannelloniFourchette from "../images/cannelloni-fourchette.jpg"
import imagePlatsTraiteur from "../images/plats-traiteur.jpg"
import imagePlatViande from "../images/plat-viande.jpg"
import imageRayonTraiteur from "../images/rayon-traiteur.jpg"
import imageFromage from "../images/fromage.jpg"
import imageCannellonieFourchette2 from "../images/cannelloni-fourchette-2.jpg"

// Menus
import {
  january,
  february,
  march,
  april,
  may,
  june,
  july,
  august,
  september,
  october,
  november,
  december,
} from "../components/menu-traiteur"

const menusTraiteur = [january, february, march, april, may, june, july, august, september, october, november, december]

const buildMenu = (menu) =>
  <div>
    {
      menu.map(m =>
        <div>
          <P className="text-center text-muted">{ m.title }</P>
          <ul className="mb-5">
            {
              m.list.map(l => <PSmall className="font-weight-bold">· { l }</PSmall>)
            }
          </ul>
        </div>
      )
    }
  </div>

const getCurrentMenu = () => {
  const menu = menusTraiteur[new Date().getMonth()]

  if (menu)
    return buildMenu(menu)
  else
    return <p>Les menus de la semaine ne sont pas encore disponible.</p>
}

const Page = () =>
  <div>
    <SEO title="Traiteur" />
    <Header/>

    <Hero title="Traiteur italien" subtitle="Découvrez notre carte de plats faits maison, élaborés quotidiennement dans nos cuisines à Gembloux" image={imageCannelloniFourchette}/>

    <Section className="container" style={{marginTop: "60px", marginBottom: "60px"}}>
      <div className="row">
        <div className="col-md-8 offset-md-2">
          <H1 className="text-center">Notre carte</H1>
            <H3 className="text-center">
              Afin de vous assurer le meilleur service, nous vous conseillons de réserver vos commandes à l’avance <br/>
              <br/>
              par téléphone au <strong>081 61 46 10</strong> <br/>
              ou <br/>
              par email à <strong>gembloux@raineri.be</strong>
            </H3>
        </div>
      </div>
    </Section>

    <Section className="container mb-5 p-5" style={{backgroundColor: "#F8F8F8"}}>
      <div className="row">
        <div className="col-md-6 offset-md-3 text-center">
          <H2>Menu du mois par email</H2>
          <PSmall>Recevez le menu complet une fois par mois dans votre boite mail.</PSmall>
          <form action="https://rainerigembloux.us4.list-manage.com/subscribe/post?u=b6a45079797bf926633d0d07f&amp;id=6e81180566" method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form" target="_blank">
            <div id="mc_embed_signup_scroll" className="form-group row">
              <input type="email" name="EMAIL" id="mce-EMAIL" placeholder="Adresse email" className="form-control col-7" required/>
              <input type="submit" value="S'inscrire" name="subscribe" id="mc-embedded-subscribe" className="btn btn-dark col-4 offset-1"/>
            </div>
          </form>
          <PSmall style={{fontSize: "13px"}}>Votre adresse email ne sera jamais utilisée, partagée ou revendue à un parti tier.</PSmall>
        </div>
      </div>
    </Section>

    <Section className="container">
      <div className="row">


        {/* Carte du mois */}
        <div className="col-md-6 mb-5">
          <H2 className="text-center">Menu du mois</H2>
          
          <P className="text-center font-weight-bold" style={{color: "#E21D40"}}>Disponible chaque semaine à partir du jeudi</P>
          <P className="font-italic">
            Suite au contexte actuel, nous avons dû adapter nos menus traiteurs.
            Nous vous conseillons de passer commande par téléphone au 081 61 46 10 pour éviter trop d'attente dans le magasin et faciliter
            l'application des régles de distanciation.
          </P>
          <br/>

          { getCurrentMenu() }

          {/* <div className="text-center">
            <Button href="/fetes">✨ Voir menu des Fêtes 🎄</Button>
          </div> */}
        </div>

        {/* Les classiques */}
        <div className="col-md-6 mb-5">
          <H2 className="text-center">Les classiques</H2>
          <P className="text-center text-muted">Tous les jours à la carte</P>

          <PSmall className="font-weight-bold mb-1">Lasagne bolognese classique ou crème</PSmall>
          <PSmall className="text-muted mb-4">€ 14,90 / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Cannelloni ricotta / épinards</PSmall>
          <PSmall className="text-muted mb-4">€ 17,40 / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Cannelloni viande</PSmall>
          <PSmall className="text-muted mb-4">€ 16,90 / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Sauce pomarola</PSmall>
          <PSmall className="text-muted mb-4">€ 7,50 / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Sauce bolognese</PSmall>
          <PSmall className="text-muted mb-4">€ 11,50 / kg</PSmall>

          <PSmall className="font-weight-bold mb-1">Pizza maison</PSmall>
          <PSmall className="text-muted mb-4">€ 12,60 / kg</PSmall>
        </div>
      </div>
    </Section>

    <Section className="container mb-5">
      <div className="row">
        <div className="col-md-6">
          <img src={imagePlatsTraiteur} className="img-fluid rounded" />
        </div>
        <div className="col-md-6">
          <img src={imagePlatViande} className="img-fluid rounded"/>
        </div>
      </div>
    </Section>

    <Section className="container mb-5">
      <div className="row">
        <div className="col-md-4">
          <img src={imageRayonTraiteur} className="img-fluid rounded"/>
        </div>
        <div className="col-md-4">
          <img src={imageFromage} className="img-fluid rounded"/>
        </div>
        <div className="col-md-4">
          <img src={imageCannellonieFourchette2} className="img-fluid rounded"/>
        </div>
      </div>
    </Section>

    <Footer/>
  </div>

export default Page
